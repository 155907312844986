import { useState, useEffect } from 'react';
// import Button from 'react-bootstrap/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import productList from 'services/productlist';


const Tables = () => {

  const [productLists, setProductLists] = useState();

  useEffect(() => {

    const lists = async () => {
      const result = await productList();
      setProductLists(result.data);
    }

    lists();

  }, []);


  return (
    <div>

      <div className=" mt-10 Hero-section ">

        {/* <div className='add-categories-btn dark:text-white' > <Button variant="primary">Add Categories +</Button></div> */}

        <Table responsive="md" className='table table-striped Hero-table dark:text-white bg-dark '>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Category</Th>
              <Th>In Stock</Th>
              <Th>Sold Out</Th>
              {/* <Th>total Stock</Th> */}
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>

            {(Array.isArray(productLists) && productLists.length > 0) ? productLists.map((key, index) => {
              
              return (
                <Tr key={key._id}>
                  <Td>{index + 1}</Td>
                  <Td>{key.categoryId?.categoryName}</Td>
                  <Td>{key.productInStock}</Td>
                  <Td>{key.soldOutItem ? key.soldOutItem : "--"}</Td>
                  {/* <Td></Td> */}
                  <Td>{key.productInStock === 0 ? 'Out Of Stock' : ""}</Td>
                </Tr>
              )
            }) : <>

            </>}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default Tables;
