import auth from "./auth";
import axios from "axios";


const productList = async() => {

    try {
        const result = await axios.get(`http://43.205.237.213:3003/api/product/search`);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;
    }
}

export default productList;
