import { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import allOrderDetails from 'services/getAllOrders';


const Tables = () => {

  const [orderDetails, setOrderDetails] = useState();

  useEffect(() => {
    const orderDetails = async () => {

      const orderResult = await allOrderDetails();
      setOrderDetails(orderResult.data);

    }

    orderDetails()
  }, []);

  return (
    <div>

      <div className=" mt-10 Hero-section ">

        <Table responsive="md" className='table table-striped Hero-table dark:text-white bg-dark '>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Product Id</Th>
              <Th>Product Name</Th>
              <Th>Customer Name</Th>
              <Th>Date</Th>
              <Th>Email</Th>
              <Th>order status</Th>
              <Th>Total</Th>
            </Tr>
          </Thead>
          <Tbody>

            {Array.isArray(orderDetails) && orderDetails.length > 0 ? orderDetails.map((key, index) => {

              let localDate = new Date(key.createdAt);
              localDate = localDate.toLocaleDateString();

              return (
                <Tr>
                  <Td>{index+1}</Td>
                  <Td>#{key.productId.productCustomId}</Td>
                  <Td>{key.productId.productName}</Td>
                  <Td>{key.userId?.name}</Td>
                  <Td>{localDate}</Td>
                  <Td>{key.userId?.email}</Td>
                  {/* IF DELIVERED style={{ color: "green" }} */}
                  <Td>{key.shippingStatus}</Td>
                  <Td>{key.totalAmount}</Td>
                </Tr>
              )
            }) : <></>}

          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default Tables;
