import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ToastContainer } from 'react-toastify';
import { ShowErrorToast } from 'components/error/error';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import addProduct from '../../services/addproduct';
import categoryAllItem from 'services/categoryList';

const ProductAdd = ({ getStatusUpdate }) => {

    let name, value;
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedOption, setSelectedOption] = useState();
    const [option] = useState([]);
    const [customProductNo, setCustomProductNo] = useState(0);

    const [productValue, setProductValue] = useState({
        productName: "",
        productImage: "",
        productInStock: 0,
        metalType: "",
        description: "",
        genderTypeJewellery: "",
        categoryType: "",
        productSize: 0,
        price: 0,
        discount: 0
    });

    useEffect(() => {
        const max = 999999;
        const min = 100;
        const productNo = Math.floor(Math.random() * (max - min + 1) + min);
        setCustomProductNo(productNo); 
        const categoryName = async () => {
            const listOfCategoryName = await categoryAllItem();
            listOfCategoryName.data.map(key => {
                option.push({ label: key.categoryName, value: key._id })
            })
        }

        categoryName();
    }, []);

    const handleInput = (e) => {
        name = e.target.name;
        value = e.target.value;
        setProductValue({ ...productValue, [name]: value });
    }

    const submitHandle = async () => {

        if (!productValue.productName) {
            return ShowErrorToast('Product name is required!');
        }
        if (!selectedImage) {
            return ShowErrorToast('Please select a image is required!');
        }
        if (!productValue.productInStock) {
            return ShowErrorToast('Please add total piece of products!');
        }
        if (!productValue.metalType) {
            return ShowErrorToast('Please add metal type!');
        }
        if (!productValue.genderTypeJewellery) {
            return ShowErrorToast('Please add jewellery for men or women!');
        }
        if (!selectedOption) {
            return ShowErrorToast('Please add category!');
        }
        if (!productValue.price) {
            return ShowErrorToast('Please add price!');
        }
        if (!productValue.productSize) {
            return ShowErrorToast('Please add size!');
        }

        const formData = new FormData();
        formData.append('image', selectedImage);
        formData.append('productName', productValue.productName);
        formData.append('productInStock', productValue.productInStock);
        formData.append('metalType', productValue.metalType);
        formData.append('description', productValue.description ? productValue.description : "");
        formData.append('genderTypeJewellery', productValue.genderTypeJewellery);
        formData.append('categoryId', selectedOption);
        formData.append('price', productValue.price);
        formData.append('discount', productValue.discount);
        formData.append('productSize', productValue.productSize);
        formData.append('productCustomId', customProductNo);

        try {
            const productResult = await addProduct(formData);
            if (productResult.status === 200) {
                Swal.fire({
                    title: productResult.message,
                    confirmButtonText: 'Ok'
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            handleClose();
                            setProductValue({
                                productName: "",
                                productImage: "",
                                productInStock: 0,
                                metalType: "",
                                description: "",
                                genderTypeJewellery: "",
                                categoryType: "",
                                productSize: 0,
                                price: 0,
                                discount: 0
                            });
                            setSelectedImage("");
                            setSelectedOption("")
                        }
                    });

                getStatusUpdate('productList');

            }
        } catch (err) {
            console.log(err, 'mmmmmmmmmm');
        }

    }

    const handleClose = () => {
        setShow(false)
    };

    const handleShow = () => setShow(true);

    const imageChange = (event) => {
        setSelectedImage(event.target.files[0]);
    }

    const handleChange = (option) => {
        setSelectedOption(option.value);
    };

    return (
        <>
            <button onClick={handleShow}>Add Product +</button>

            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className='pop-up' closeButton>
                    <Modal.Title className='pop-up_title'>Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">Category</Form.Label>
                            <Col sm="10">
                                <Select
                                    onChange={handleChange}
                                    options={option}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Product Name</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="productName"
                                    value={productValue.productName}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                /></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Image</Form.Label>
                            <Col sm="10">
                                {selectedImage && (
                                    <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            src={URL.createObjectURL(selectedImage)}
                                        />
                                        <br />
                                        <button className='btn-dash text-black' onClick={() => setSelectedImage("")}>Remove</button>
                                    </div>
                                )}
                                <br />
                                <br />
                                <input
                                    type="file"
                                    name="webImage"
                                    onChange={(event) => { imageChange(event) }}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label column sm="2">Stock</Form.Label>
                            <Col sm="10">
                                <Form.Control value={productValue.productInStock} type="number" name="productInStock" onChange={handleInput} autoFocus required />
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Price</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="number"
                                    name="price"
                                    value={productValue.price}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Discount</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="number"
                                    name="discount"
                                    value={productValue.discount}
                                    onChange={handleInput}
                                /></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Metal Type</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="metalType"
                                    value={productValue.metalType}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                /></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Description</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={productValue.description}
                                    onChange={handleInput}
                                /></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Gender Type Jewellery</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="genderTypeJewellery"
                                    value={productValue.genderTypeJewellery}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                /></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Product Size</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="number"
                                    name="productSize"
                                    value={productValue.productSize}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                /></Col>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-dash text-black' type="submit" onClick={submitHandle}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default ProductAdd;
