import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ToastContainer } from 'react-toastify';
import { ShowErrorToast } from 'components/error/error';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import editCategory from 'services/editCategory';
import getCategoryBYID from 'services/getCategoryById';

const EditCategory = ({ getStatusUpdate, status, ID, showUpdate }) => { //handleShow

    let name, value;
    const [show, setShow] = useState(status);
    const [selectedImage, setSelectedImage] = useState("");
    const [categoryListData, setCategoryListData] = useState();
    const [editPreviewImage, setEditPreviewImage] = useState();

    const [categoryValue, setCategoryValue] = useState({
        categoryName: "",
        mobileImg: "",
        webImg: ""
    });

    const preFieldData = () => {
        if(categoryListData) {
            setCategoryValue({
                categoryName: categoryListData.categoryName,
                mobileImg: categoryListData.mobileImg,
                webImg: categoryListData.webImg
            });
            setEditPreviewImage(categoryListData.mobileImg);
        }

    } 

    useEffect(() => {

        getStatusUpdate('categoryEditPage');
        const getCategoryById = async() => {

            const result = await getCategoryBYID(ID);
            setCategoryListData(result.data);

        }
        getCategoryById();
        preFieldData();

    }, [!categoryListData]);

    const handleInput = (e) => {
        name = e.target.name;
        value = e.target.value;
        setCategoryValue({ ...categoryValue, [name]: value });
    }

    const submitHandle = async () => {
        
        if(!(categoryValue.categoryName)) {
            return ShowErrorToast('Category name is required!');
        }
        if(!(selectedImage || editPreviewImage)) {
            return ShowErrorToast('Image is required!');
        }
        const formData = new FormData();
        formData.append('categoryName', categoryValue.categoryName);
        formData.append('image', selectedImage);

        try{
            const categoryResult = await editCategory(formData, ID);
            if(categoryResult.status === 200) {
                Swal.fire({
                    title: categoryResult.message,
                    confirmButtonText: 'Ok'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        handleClose();
                        setCategoryValue({
                            categoryName: "",
                            mobileImg: "",
                            webImg: ""
                        });
                        setSelectedImage("");
                    }
                });
                getStatusUpdate('categoryEditPage');
                showUpdate(false);

            }
        } catch(err) {
            console.log(err, 'mmmmmmmmmm');
        }

    }

    const handleClose = () => {
        setShow(false);
        showUpdate(false);
    };

    const imageChange = (event) => {
        setSelectedImage(event.target.files[0]);
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className='pop-up' closeButton>
                    <Modal.Title className='pop-up_title'>Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">Category Name</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="categoryName"
                                    value={categoryValue.categoryName}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Image</Form.Label>
                            <Col sm="10">
                                {(selectedImage || editPreviewImage) && (
                                    <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            src={editPreviewImage ? editPreviewImage.path : URL.createObjectURL(selectedImage)}
                                        />
                                        <br />
                                        <button className='btn-dash text-black' onClick={() => {setSelectedImage(""); setEditPreviewImage("")}}>Remove</button>
                                    </div>
                                )}
                                <br />
                                <br />
                                <input
                                    type="file"
                                    name="webImage"
                                    onChange={(event) => { imageChange(event) }}
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-dash text-black' type="submit" onClick={submitHandle}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default EditCategory;
