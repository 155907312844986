import React, { useMemo, useEffect, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import { Table, Thead } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const CheckTable = (props) => {

  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  useEffect(() => {

  }, [tableData])

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-white">
          Customer Detail
        </div>

        {/* <CardMenu /> */}
      </header>

      <div className="mt-8 xl:overflow-x-hidden">
        <Table>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Birthday</th>
            <th>Country</th>
          </tr>
          {/* <tbody> */}
            {(Array.isArray(tableData) && tableData.length > 0) ? tableData.map(key => {
              return (
                <tr>
                  <td>{key.name}</td>
                  <td>{key.email}</td>
                  <td>{key.birthDate === "Invalid Date" ? "--" : key.birthDate}</td>
                  <td>{key.country}</td>
                </tr>
              )
            }) : <>
            </>}
          {/* </tbody> */}
        </Table>
        {/* <Table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      {column.render("Header")}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          

          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {(Array.isArray(tableData) && tableData.length > 0) ? <>
                    {row.cells.map((cell, index) => {

                      console.log(cell.column.Header, "cell.column.Header");
                      console.log("cell.value", cell.value);

                      let data = "";
                      if (cell.column.Header === "NAME") {
                        data = (
                          <div className="flex items-center gap-2">

                            <p className="text-sm font-bold text-white">
                              {cell.value}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "EMAIL") {
                        data = (
                          <div className="flex items-center">
                            <p className=" dark:text-white text-sm font-bold">
                              {cell.value}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === "BIRTHDATE") {
                        data = (
                          <p className="text-white text-sm font-bold">
                            {" "}
                            {cell.value}{" "}
                          </p>
                        );
                      } else if (cell.column.Header === "COUNTRY") {
                        data = (
                          <p className=" text-white text-sm font-bold ">
                            {cell.value}
                          </p>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="pt-[14px] pb-[16px] sm:text-[14px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </> : <></>}

                </tr>
              );
            })}
          </tbody>  
        </Table> */}
      </div>
    </Card>
  );
};

export default CheckTable;
