import auth from "./auth";
import axios from "axios";

const allCustomerDetails = async() => {
    
    const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhcm1pc3RoYW1haXR5OTBAZ21haWwuY29tIiwiaWF0IjoxNjk3MTA0NjYxfQ.3iqPpOC1J-XqAa15Q8UUWh19RBDRm0_v3pX5MLJH9v4";
    const config = {
        headers: {
            Authorization: authToken
        }
    };

    try {
        const result = await axios.get(`http://43.205.237.213:3003/api/user/alluser`, config);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;

    }
}


export default allCustomerDetails;
