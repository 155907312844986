export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "BIRTHDATE",
    accessor: "birthdate",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  }
];


export const columnsDataComplex = [
  {
    Header: "PRODUCT_ID",
    accessor: "product-id",
  },
  {
    Header: "PRODUCT_NAME",
    accessor: "product-name",
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "PRICE",
    accessor: "price",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
];
