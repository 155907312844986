import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ToastContainer } from 'react-toastify';
import { ShowErrorToast } from 'components/error/error';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import ImageUploader from 'services/imageUploader';

const AddImageForHeroSection = ({ getStatusUpdate, state }) => {

    let name, value;
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [checked, setChecked] = useState(false);
    const [isApp, setIsApp] = useState();
    const [isWeb, setIsWeb] = useState();


    const [imageNameValue, setImageNameValue] = useState({
        imageName: "",
        isApp: false,
        isWeb: false
    });

    const handleInput = (e) => {
        name = e.target.name;
        value = e.target.value;
        setImageNameValue({ ...imageNameValue, [name]: value });
    }

    const submitHandle = async () => {

        if (!imageNameValue.imageName) {
            return ShowErrorToast('Image name is required!');
        }
        if (!selectedImage) {
            return ShowErrorToast('Please select a image is required!');
        }

        const formData = new FormData();
        formData.append('imageName', imageNameValue.imageName);
        formData.append('image', selectedImage);
        formData.append("isApp", isApp ? isApp : imageNameValue.isApp);
        formData.append("isWeb", isWeb ? isWeb : imageNameValue.isWeb);

        try {
            const uploadResult = await ImageUploader(formData);
            if (uploadResult.status === 200) {
                Swal.fire({
                    title: uploadResult.message,
                    confirmButtonText: 'Ok'
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            handleClose();
                            setImageNameValue({
                                imageName: "",
                                isApp: "",
                                isWeb: ""
                            });
                            setSelectedImage("");
                        }
                    });
                getStatusUpdate('addImage');

            }
        } catch (err) {
            console.log(err, 'mmmmmmmmmm');
        }

    }

    const handleClose = () => {
        setShow(false)
    };

    const handleShow = () => setShow(true);

    const imageChange = (event) => {
        setSelectedImage(event.target.files[0]);
    }

    const handleCheckBox = (e, value) => {
       
        if(value === "isApp") {
            setIsApp(true);
        }
        if(value === "isWeb") {
            setIsWeb(true);
        }

        setChecked(e.target.checked);
    }

    return (
        <>
            <button onClick={handleShow}>Add Image +</button>

            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className='pop-up' closeButton>
                    <Modal.Title className='pop-up_title'>Hero </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">Image Name</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="imageName"
                                    value={imageNameValue.imageName}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label column sm="2">Image</Form.Label>
                            <Col sm="10">
                                {selectedImage && (
                                    <div>
                                        <img
                                            alt="not found"
                                            width={"250px"}
                                            src={URL.createObjectURL(selectedImage)}
                                        />
                                        <br />
                                        <button className='btn-dash text-black' onClick={() => setSelectedImage("")}>Remove</button>
                                    </div>
                                )}
                                <br />
                                <br />
                                <input
                                    type="file"
                                    name="webImage"
                                    onChange={(event) => { imageChange(event) }}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">For App</Form.Label>
                            <Col sm="5">
                                <input type="checkbox" onChange={(e) => handleCheckBox(e, "isApp")} />

                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">For Web</Form.Label>
                            <Col sm="5">
                                <input type="checkbox" onChange={(e) => handleCheckBox(e, "isWeb")} />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-dash text-black' type="submit" onClick={submitHandle}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default AddImageForHeroSection;
