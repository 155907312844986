import auth from "./auth";
import axios from "axios";

const getCategoryBYID = async(ID) => {
    
    const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhcm1pc3RoYW1haXR5NjhAZ21haWwuY29tIiwiaWF0IjoxNjk2OTQxMjA0fQ.Phmwl06NllrLrIzdwimifCjqMwPEtNXFNl1Zk11FV3E"; //auth();
    
    const config = {
        headers: {
            Authorization: authToken
        }
    };

    try {
        const result = await axios.get(`http://43.205.237.213:3003/api/admin/category/item/${ID}`, config);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;
    }
}

export default getCategoryBYID;
