import { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { ToastContainer } from 'react-toastify';
import { ShowErrorToast } from 'components/error/error';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import addCoupon from 'services/couponcode/coupon';

const AddCoupon = ({ getStatusUpdate }) => {

    let name, value;
    const [show, setShow] = useState(false);
    const [randomCouponCode, setRandomCouponCode] = useState();
    const [isActive, setIsActive] = useState();
    let [couponValue, setCouponValue] = useState({
        couponCode: randomCouponCode,
        couponCodeTitle: "",
        fromDate: new Date(),
        toDate: new Date(),
        discount: 0,
        isActive: false
    });

    useEffect(() => {

        const gfg = () => {
            const minm = 1000;
            const maxm = 9999;
            setRandomCouponCode(Math.floor(Math.random() * (maxm - minm + 1)) + minm);
        }

        gfg();

    }, []);


    const handleInput = (e) => {
        name = e.target.name;
        value = e.target.value;
        setCouponValue({ ...couponValue, [name]: value });
    }

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const submitHandle = async () => {

        couponValue.couponCode = randomCouponCode;
        couponValue.isActive = isActive ? isActive : false
        if (!(couponValue.couponCode)) {
            return ShowErrorToast('Coupon Code is required!');
        }
        if (!couponValue.fromDate) {
            return ShowErrorToast('Please select from date!');
        }
        if (!couponValue.toDate) {
            return ShowErrorToast('Please select to date!');
        }
        if (!couponValue.couponCodeTitle) {
            return ShowErrorToast('Please give coupon code title!');
        }
        const fromDateConvert = convert(couponValue.fromDate);
        const toDateConvert = convert(couponValue.toDate);

        const checkFromDate = new Date(fromDateConvert);
        const checkToDate = new Date(toDateConvert);

        if (checkFromDate <= checkToDate) {
            //
        } else {
            return ShowErrorToast('Please select To date after a day or same day atleast!');
        }

        try {
            const couponResult = await addCoupon(couponValue);
            if (couponResult.status === 200) {
                Swal.fire({
                    title: couponResult.message,
                    confirmButtonText: 'Ok'
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            handleClose();
                            setCouponValue({
                                couponCode: 0,
                                fromDate: new Date(),
                                toDate: new Date(),
                                discount: 0,
                                isActive: false,
                                couponCodeTitle: ""
                            });
                        }
                    });
                getStatusUpdate('couponList');

            }
        } catch (err) {
            console.log(err, 'mmmmmmmmmm');
        }
    }

    const handleClose = () => {
        setShow(false)
    };

    const handleShow = () => setShow(true);

    const handleCheckBox = (e) => {
        setIsActive(e.target.checked);
    }

    return (
        <>
            <button onClick={handleShow}>Add Coupon +</button>

            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className='pop-up' closeButton>
                    <Modal.Title className='pop-up_title'>Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">Coupon Title</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="text"
                                    name="couponCodeTitle"
                                    value={couponValue.couponCodeTitle}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">Coupon Code</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="number"
                                    name="couponCode"
                                    value={randomCouponCode}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">From Date</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="date"
                                    name="fromDate"
                                    value={couponValue.fromDate}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">To Date</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="date"
                                    name="toDate"
                                    value={couponValue.toDate}
                                    onChange={handleInput}
                                    autoFocus
                                    required
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">Discount</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="number"
                                    name="discount"
                                    value={couponValue.discount}
                                    onChange={handleInput}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1"  >
                            <Form.Label column sm="2">Status</Form.Label>
                            <Col sm="10">
                                <input type="checkbox" className='check' style={{ "-webkit-appearance": "auto" }} onChange={(e) => handleCheckBox(e)} />
                            </Col>
                        </Form.Group>


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-dash text-black' type="submit" onClick={submitHandle}>
                        Save
                    </button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </>
    );
}

export default AddCoupon;
