import { useState, useEffect } from "react";
import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import Stockchart from "views/admin/default/components/Stockchart"
import PieChartCard from "views/admin/default/components/PieChartCard";
import { MdBarChart, MdDashboard, MdPerson, MdOutlineWaves } from "react-icons/md";
import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";
import Widget from "components/widget/Widget";
import Widget1 from "components/widget/widget1";
// import Widget2 from "components/widget/widget2";
import Widget3 from "components/widget/widget3";
import CheckTable from "views/admin/default/components/CheckTable";
// import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import RecentlyTable from "views/admin/default/components/recentlyOrder";
import allCustomerDetails from "services/getAllUserDetail";
import categoryAllItem from "services/categoryList";
import productList from "services/productlist";
import allOrderDetails from "services/getAllOrders";

const Dashboard = () => {

  const [allCustomerDetail, setAllcustomerDetail] = useState();
  const [noOfCustomer, setNoOfCustomer] = useState();
  const [noOfCategory, setNoOfCategory] = useState();
  const [noOfProduct, setNoOfProduct] = useState();
  const [mapingData] = useState([
    {
      name: "",
      email:"",
      birthdate: "",
      country: ""
    }]);

  const [recentOrderData, setRecentOrderData] = useState();

  useEffect(() => {

    const dashBoardDetail = async () => {

      const customerDetails = await allCustomerDetails();
      const category = await categoryAllItem();
      const productData = await productList();
      const orderDetail = await allOrderDetails();
      const data = orderDetail.data.slice(Math.max(orderDetail.data.length - 5, 1));
      setRecentOrderData(data);

      setAllcustomerDetail(customerDetails.data)
      setNoOfCategory((Array.isArray(category.data) && category.data.length === 0 ? 0 : category.data.length)); //category.data.length
      setNoOfProduct((Array.isArray(productData.data) && (productData.data.length === 0) ? 0 : productData.data.length));

    }

    dashBoardDetail();
    
    
  }, []);

  const mappingData = () => {
    if(Array.isArray(allCustomerDetail) && allCustomerDetail.length >0) {

      allCustomerDetail.map(key => {

        const firstName = key.firstName ? key.firstName : key.name;
        const middleName = key.middleName ? key.middleName : "";
        const lastName = key.surName ? key.surName : "";
        const fullName = firstName + middleName + lastName;
  
        if(key.userType !== "Admin") {

          let localDate = new Date(key.birthDate);
          localDate = localDate.toLocaleDateString();

          mapingData.push(
            {
              name: fullName,
              email: key.email ? key.email : "",
              birthDate: localDate ? localDate : "--",
              country: key.country ? key.country : ""
            }
          )

        }
  
      })

    }

  }

  mappingData();

  return (
    <div className="container">
      {/* Card widget */}

      <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-3">
        

        <Widget1
          icon={<MdPerson className="h-7 w-7 " />}
          title={"Total User"}
          subtitle={`${allCustomerDetail ? allCustomerDetail.length : 0}+`}
        />


        {/* <Widget2
          icon={<MdOutlineWaves className="h-6 w-6 " />}
          title={"Total Earnings"}
          subtitle={"12000+"}
        /> */}

        <Widget3
          icon={<MdBarChart className="h-7 w-7 " />}
          title={"Total Categories"}
          subtitle={`${noOfCategory}+`}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Total Product"}
          subtitle={`${noOfProduct}+`}
        />

      </div>


      {/* Charts */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />

      </div>

      {/* customer Detail &  category stock chart */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
        <CheckTable
            columnsData={columnsDataCheck}
            tableData={mapingData}
          />
          
        </div>
        <Stockchart />
      </div>


      {/* recently order list  */}
      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        <div>
          <RecentlyTable
            columnsData={columnsDataComplex}
            tableData={tableDataComplex}
            datas={recentOrderData}
          />
        </div>

        {/*  calender & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <MiniCalendar />
          <PieChartCard />
        </div>

      </div>
    </div>
  );
};

export default Dashboard;
