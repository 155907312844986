import { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Image from 'assets/img/avatars/avatar3.png';
import AddImageForHeroSection from 'components/heroSection/addImage';
import EditImageById from 'components/heroSection/editImage';
import { getImages } from 'services/imageUploader';

const Tables = () => {

  const [status, setStatus] = useState();
  const [show, setShow] = useState(false);
  const [imageId, setImageId] = useState();
  const [herSectionData, setHeroSectionData] = useState();

  useEffect(() => {

    const allImagesData = async () => {

      const allImages = await getImages();
      setHeroSectionData(allImages.data);

    }

    allImagesData();

  }, []);

  const statusUpdate = (data) => {
    setStatus(data);
  }

  const redirectToEditPage = (imageID) => {
    setShow(true);
    setImageId(imageID);

  }

  const showState = (state) => {
    setShow(state);
  }

  return (
    <div>
      <div className=" mt-10 Hero-section ">
        <div className='add-categories-btn dark:text-white' >
          {/* <button className="btn-dash">Add category +</button> */}
          <button className="btn-dash">
            <AddImageForHeroSection getStatusUpdate={statusUpdate} />
          </button>
        </div>

        <Table responsive="md" className=' table-striped dark:text-white  bg-dark Hero-table table'>

          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Image name</Th>
              <Th> Web image</Th>
              <Th>Mobile image</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>

          <Tbody >

            {(Array.isArray(herSectionData) && herSectionData.length > 0) ? herSectionData.map((key, index) => {
              
              return (

                <Tr key={key._id}>
                  <Td id="hero-tr">{index+1}</Td>
                  <Td>{key.imageName}</Td>
                  <Td><img src={key.image.path} style={{ width: '50px' }} /></Td>
                  <Td><img src={key.image.path} style={{ width: '50px' }} /></Td>
                  <Td>
                    <button type="button" class="btn btn-primary" onClick={() => redirectToEditPage(key._id)}>Edit</button>{" "}
                    {/* <button type="button" class="btn btn-primary" onClick={() => redirectToViewPage(key._id)}>View</button> */}
                  </Td>
                </Tr>
              )
            }) : <>

            </>}


          </Tbody>
        </Table>
        {show && <EditImageById getStatusUpdate={statusUpdate} status={show} ID={imageId} showUpdate={showState} />}
      </div>
    </div>
  );


};

export default Tables;
