import { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { getCoupon } from 'services/couponcode/coupon';
import AddCoupon from 'components/coupons/addcoupon';

const Tables = () => {

  const [status, setStatus] = useState();
  const [show, setShow] = useState(false);
  const [allCoupon, setAllCoupon] = useState();


  const statusUpdate = (data) => {
    setStatus(data)
  }

  const showState = (state) => {
    setShow(state);
  }

  useEffect(() => {

    const couponsList = async () => {

      const coupons = await getCoupon();
      setAllCoupon(coupons.data);

    }
    couponsList();

  }, []);


  return (
    <div>

      <div className=" mt-10 Hero-section ">
        <div className='add-Product-btn dark:text-white' >
          <button className="btn-dash">
            <AddCoupon getStatusUpdate={statusUpdate} />
          </button>
        </div>

        <Table responsive="md" className='table table-striped Hero-table dark:text-white bg-dark '>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Title</Th>
              <Th>CoupanCode</Th>
              <Th>Discount</Th>
              <Th>Status</Th>
              <Th>start Date</Th>
              <Th>Enad Date</Th>
              {/* <Th>Action</Th> */}
            </Tr>
          </Thead>
          <Tbody>

            {(Array.isArray(allCoupon) && allCoupon.length > 0) ?

              allCoupon.map((key, index) => {

                const fDate = new Date(key.fromDate);
                const tDate = new Date(key.toDate);

                const formDateLocal = new Intl.DateTimeFormat('en-US').format(fDate);
                const toDateLocal = new Intl.DateTimeFormat('en-US').format(tDate);

                return (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{key.couponCodeTitle}</Td>
                    <Td>{key.couponCode}</Td>
                    <Td>{key.discount}%</Td>
                    <Td><input type="checkbox" className='check' checked={key.isActive}/>{key.isActive == true ? "Active" : "inActive"}</Td>
                    <Td>{formDateLocal}</Td>
                    <Td>{toDateLocal}</Td>
                    {/* <Td>edit</Td> */}
                  </Tr>
                )
              })
              : <>
              </>}


          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default Tables;
