import { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import AddCategory from 'components/addCategory/addCategory';
import EditCategory from 'components/addCategory/editCategory';
import categoryAllItem from 'services/categoryList';

const Tables = () => {
  const [status, setStatus] = useState();
  const [show, setShow] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState('')

  const statusUpdate = (data) => {
    setStatus(data)
  }

  useEffect(() => {

    const categoryListData = async () => {
      const result = await categoryAllItem();
      setCategoryList(result.data);

    }

    categoryListData();

  }, [status]);

  const redirectToEditPage = (categoryId) => {
    setShow(true);
    setCategoryId(categoryId);

  }

  const showState = (state) => {
    setShow(state);
  }


  return (
    <div className='container'>
      <div className="row mt-10 Hero-section ">
        <div className='add-categories-btn dark:text-white' >
          <button className="btn-dash">
            <AddCategory getStatusUpdate={statusUpdate} />
          </button>
        </div>

        <Table responsive="md" className='table table-striped Hero-table dark:text-white bg-dark '>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Categoiry Name</Th>
              <Th>Web Image</Th>
              <Th>Mobile Image</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>

            {categoryList.map((key, index) => {

              return (
                <Tr key={key._id}>
                  <Td>{index+1}</Td>
                  <Td>{key.categoryName}</Td>
                  <Td><img src={key.webImg.path} style={{ width: '50px' }} /></Td>
                  <Td><img src={key.mobileImg.path} style={{ width: '50px' }} /></Td>
                  <Td>
                    <button type="button" class="btn btn-primary" onClick={() => redirectToEditPage(key._id)}>Edit</button>
                    {/* <button type="button" class="btn btn-primary">View</button> */}
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        {show && <EditCategory getStatusUpdate={statusUpdate} status={show} ID={categoryId} showUpdate={showState}/>}
      </div>
    </div>
  );
};

export default Tables;
