import auth from "./auth";
import axios from "axios";

const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhcm1pc3RoYW1haXR5NjhAZ21haWwuY29tIiwiaWF0IjoxNjk2OTQxMjA0fQ.Phmwl06NllrLrIzdwimifCjqMwPEtNXFNl1Zk11FV3E"; //auth();
const ImageUploader = async(data) => {
    
    
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: authToken
        }
    };

    try {
        const result = await axios.post(`http://43.205.237.213:3003/api/admin/imageuploader`, data, config);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;
    }
}

export const getImages = async() => {
        
    const config = {
        headers: {
            Authorization: authToken
        }
    };

    try {
        const result = await axios.get(`http://43.205.237.213:3003/api/admin/images`, config);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;
    }
}

export const getImageById = async(ID) => {
    
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: authToken
        }
    };

    try {
        const result = await axios.get(`http://43.205.237.213:3003/api/admin/image/${ID}`, config);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;
    }
}

export const updateImageById = async(data, ID) => {
        
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: authToken
        }
    };

    try {
        const result = await axios.put(`http://43.205.237.213:3003/api/admin/image/${ID}`, data, config);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;
    }
}

export default ImageUploader;
