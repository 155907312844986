import { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import allCustomerDetails from 'services/getAllUserDetail';

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

const { format } = new Intl.DateTimeFormat('en-US', options);

const Tables = () => {
  
  const [allCustomerDetail, setAllcustomerDetail] = useState();
  let index = 0;

  useEffect(() => {

    const customerDetails = async () => {

      const customerDetails = await allCustomerDetails();
      setAllcustomerDetail(customerDetails.data);

    }

    customerDetails();
    
  }, []);


  return (


    <div>
      <div className=" mt-20 Hero-section ">
        <Table responsive="md" className='table table-striped Hero-table dark:text-white bg-dark '>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>First Name</Th>
              <Th>Middle Name</Th>
              <Th>Surname Name</Th>
              <Th>BirThDate</Th>
              <Th>Email</Th>
              <Th>Phone</Th>
              {/* <Th>Address</Th> */}
              <Th>City</Th>
              <Th>State</Th>
              <Th>Country</Th>
              <Th>Pincode</Th>
              {/* <Th>Action</Th> */}
            </Tr>
          </Thead>
          <Tbody>

            {Array.isArray(allCustomerDetail) && allCustomerDetail.length > 0 ? allCustomerDetail.map((key) => {

              if (key.userType === "user") {
                let customerBirthDate = key.birthDate ? key.birthDate : new Date();
                let formatDate = new Date(customerBirthDate);
                index = index + 1;
                return (
                  <Tr>
                    <Td>{index}</Td>
                    <Td>{key.firstName ? key.firstName : ""}</Td>
                    <Td>{key.middleName ? key.middleName : ""}</Td>
                    <Td>{key.surName ? key.surName : ""}</Td>
                    <Td>{format(formatDate)}</Td>
                    <Td>{key.email}</Td>
                    <Td>{key.phone ? key.phone : "00"}</Td>
                    {/* <Td>Bopal</Td> */}
                    <Td>{key.City ? key.City : ""}</Td>
                    <Td>{key.state ? key.state : ""}</Td>
                    <Td>{key.country ? key.country : ""}</Td>
                    <Td>{key.pincode ? key.pincode : ""}</Td>
                    {/* <Td>
                      <Button variant="primary">View</Button>
                    </Td> */}
                  </Tr>
                )
              }
            }) : <></>}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default Tables;
