import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import productList from 'services/productlist';
import ProductAdd from 'components/addproduct/product';
import editProductById from 'services/editProductById';
import EditProduct from 'components/addproduct/editProduct';
import Swal from 'sweetalert2';


const Tables = () => {

  const [status, setStatus] = useState('');
  const [productLists, setProductLists] = useState([]);
  let [newArrival, setNewArrival] = useState(false);
  let [bestSeller, setBestSeller] = useState(false);
  const [productId, setProductId] = useState();
  const [show, setShow] = useState(false);
  // let [updateNewArrival] = useState(false);
  // let [updateBestSeller] = useState(false);


  const statusUpdate = (data) => {
    setStatus(data);
  }

  useEffect(() => {

    const lists = async () => {
      const result = await productList();
      setProductLists(result?.data);
    }

    lists();

  }, [status, !show]);

  const newArrivalChecked = (e, id) => {

    // if(state === "clicked") {
    //   console.log("kkkkkkkkk", e.target.checked);
    //   setNewArrival(e.target.checked);
    // } else {
    // }
    setNewArrival(e.target.checked);
    setProductId(id);

  }

  const bestSellerChecked = (e, id) => {

    // if(state === "clicked") {
    //   setBestSeller(e.target.checked);
    // }
    // else {
    // }
    setBestSeller(e.target.checked);
    setProductId(id)

  }

  const publish = async () => {

    const editPayload = {
      newArrival: newArrival,
      bestSeller: bestSeller,
      // bestSellerTag: bestSellerTag
    }
    try {
      const formData = new FormData();
      formData.append('newArrival', newArrival);
      formData.append('bestSeller', bestSeller);
      const editProduct = await editProductById(editPayload, productId);

      if (editProduct.status === 200) {
        Swal.fire({
          title: editProduct.message,
          confirmButtonText: 'Ok'
        })
          .then((result) => {
            if (result.isConfirmed) {

            }
          });
      }

    } catch (err) {
      console.log(err);
    }

  }

  const redirectToEditPage = (productId) => {
    setShow(true);
    setProductId(productId);

  }

  const showState = (state) => {
    setShow(state);
  }

  return (
    <div>

      <div className="mt-10 Hero-section">
        <div className='add-Product-btn dark:text-white' >
          <button className="btn-dash">
            <ProductAdd getStatusUpdate={statusUpdate} />
          </button>
        </div>

        <Table responsive="md" className='table-striped Hero-table dark:text-white bg-dark table'>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Product Name</Th>
              <Th>Image</Th>
              <Th>Stock</Th>
              <Th>New Arrival</Th>
              <Th>Best seller</Th>
              <Th>Category</Th>
              <Th>Price</Th>
              <Th>Discount</Th>
              <Th>Product-Price(dis.)</Th>
              <Th>Tag</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>

            {productLists?.map((key, index) => {

              const productPrice = key.price - (key.discount ? key.discount : 0);
              
              // key.newArrival = newArrival;
              // key.bestSeller = bestSeller;
              // newArrival = newArrival ? newArrival : key.newArrival;
              // bestSeller = bestSeller ? bestSeller : key.bestSeller;

              return (
                <Tr key={key._id}>
                  <Td>{index + 1}</Td>
                  <Td>{key.productName}</Td>
                  <Td><img src={key.image.path} alt='Not Found' style={{ width: '100px' }} /></Td>
                  <Td>{key.productInStock}</Td>
                  {/* {key.newArrival == true ? <>
                    <Td><input type="checkbox" className='check' checked={newArrival} onChange={(e) => newArrivalChecked(e, key._id, "clicked")} /></Td>
                  </> : <>
                  </>} */}
                    <Td><input type="checkbox" className='check' onChange={(e) => newArrivalChecked(e, key._id)} /></Td>

                  {/* {key.bestSeller == true ? <>
                    <Td><input type="checkbox" className='check' checked={bestSeller} onChange={(e) => bestSellerChecked(e, key._id, "clicked")} /></Td>
                  </> : <>
                  </>} */}
                    <Td><input type="checkbox" className='check' onChange={(e) => bestSellerChecked(e, key._id)} /></Td>
                  <Td>{key.categoryId?.categoryName}</Td>
                  <Td>{key.price}</Td>
                  <Td>{key.discount ? key.discount : 0}</Td>
                  <Td>{productPrice}</Td>
                  <Td></Td>
                  <Td>
                    <button type="button" class="btn btn-primary" onClick={() => redirectToEditPage(key._id)}>Edit</button>&ensp;
                    <button type="button" class="btn btn-primary" onClick={() => publish()}>Publish</button>
                  </Td>
                </Tr>
              )
            })}

          </Tbody>
        </Table>
        {show && <EditProduct getStatusUpdate={statusUpdate} status={show} ID={productId} showUpdate={showState} />}
      </div>
    </div>
  );
};

export default Tables;
