import Image from 'assets/img/avatars/avatar3.png';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

const Tables = () => {
  return (
    <div>

      <div className=" mt-10 Hero-section ">
        <div className='add-Product-btn dark:text-white' >
          {/* <a href="#"> <button className="btn-dash">Add +</button></a> */}
        </div>

        <Table responsive="md" className='table table-striped Hero-table dark:text-white bg-dark '>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Total amount</Th>
              <Th>Product Name</Th>
              <Th>Image</Th>
              <Th>Sold Item</Th>
              <Th>Price</Th>
            </Tr>
          </Thead>
          <Tbody>
            
            <Tr>
              <Td>1</Td>
              <Td>user</Td>
              <Td>ring</Td>
              <Td><img src={Image} style={{ width: '50px' }} /></Td>
              <Td>2</Td>
              <Td>1000$</Td>
            </Tr>

          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default Tables;
