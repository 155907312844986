import auth from "./auth";
import axios from "axios";

const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhcm1pc3RoYW1haXR5NjhAZ21haWwuY29tIiwiaWF0IjoxNjk2OTQxMjA0fQ.Phmwl06NllrLrIzdwimifCjqMwPEtNXFNl1Zk11FV3E"; //auth();
const editProductById = async(data, ID) => {
    
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: authToken
        }
    };

    try {
        const result = await axios.put(`http://43.205.237.213:3003/api/product/edit/${ID}`, data, config);
        return result.data;

    } catch(err) {
        console.log('error', err);
        return err;
    }
}

export const getProductById = async(ID) => {

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: authToken
        }
    };

    try {

        const result = await axios.get(`http://43.205.237.213:3003/api/product/${ID}`, config);
        return result.data;

    } catch(err) {
        console.log(err, 'nnnnnnnn');
    }
}

export default editProductById;
